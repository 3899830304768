import { uniq } from 'lodash';
import queryString from 'query-string';

import { ContentTypePrefixValues, typeToPrefix } from 'src/types/ContentTypes';
import { Task } from '../api/Task';
import { TicketStatuses } from '../Components/Case/contentStatuses';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { TabFilter } from 'src/types/Filter';
import type { Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

export const getTicketType = (
  serviceName: string | null,
  ticketTypes: TicketType[],
  userDefaultTicketTypeId: number
) => {
  // here we would need to change the created ticketId's ticketType based on serviceName
  let ticketType = ticketTypes.find((type) => {
    let serviceNames = [] as any;
    if (Array.isArray(type.serviceName)) {
      serviceNames = type.serviceName;
    } else {
      const typesServiceNames = type.serviceName || '';
      serviceNames = typesServiceNames.split(',');
    }
    return serviceNames.find((name: string) => {
      name = name.trim();
      return name === serviceName;
    });
  });
  if (!ticketType) {
    // did not find, use default
    ticketType = ticketTypes.find((type: TicketType) => type.serviceName === 'default');
  }

  if (!ticketType && userDefaultTicketTypeId) {
    // did not find, use user's defaultTicketType
    ticketType = ticketTypes.find((tType: TicketType) => {
      return tType.id === userDefaultTicketTypeId;
    });
  }

  if (!ticketType) {
    // did not find, use first
    ticketType = ticketTypes[0];
  }

  return ticketType;
};

export const getTicket = (ticketType: TicketType, configurationData: any): Partial<Ticket> => {
  let task = new Task();

  task.taskType = configurationData?.ticketType ? configurationData?.ticketType.name : ticketType.name;
  task.tags = configurationData?.tags ? uniq([...configurationData.tags, ...task.tags]) : task.tags;
  task.status = configurationData?.taskStatus ? configurationData.taskStatus : TicketStatuses.DOING;
  task.dueDate = configurationData?.dueDate ? configurationData.dueDate : task.dueDate;
  task.channel = configurationData?.channel ? configurationData.channel : task.channel;
  task = {
    ...task,
    id: 'new_oc',
    title: configurationData?.title || 'Phone call ticket: ' + new Date().getMinutes()
  };
  return task as Partial<Ticket>;
};

const typeToPrefixRelations: Record<ContentTypes, string> = {
  message: 'MSG',
  task: 'TSK',
  infopage: 'INF',
  poll: 'POL',
  reportitem: 'RPR'
};

export const getPrefixByType = (type?: ContentTypes) => {
  if (!type) {
    return 'TSK';
  }

  return typeToPrefixRelations[type];
};

export const convertCaseNaming = (
  caseId: string | number,
  typeOfConvertion: 'number' | 'string',
  type?: ContentTypes
) => {
  if (typeof caseId === 'number' && typeOfConvertion === 'number') {
    return caseId;
  }

  switch (typeOfConvertion) {
    case 'number': {
      return parseInt(
        ContentTypePrefixValues.reduce((prev, current) => {
          return prev.replace(current, '');
        }, caseId as string),
        10
      );
    }

    case 'string': {
      if (typeof caseId === 'number') {
        return `${typeToPrefix(type)}${caseId}`;
      }

      const isCaseIdWithPrefix = !!ContentTypePrefixValues.find((prefix) => caseId.includes(prefix));
      if (!isCaseIdWithPrefix) {
        return `${typeToPrefix(type)}${caseId}`;
      }
      return caseId;
    }
  }
};

export const getSearchParams = (normalizedPhoneNumber: string | null, ticketType: TicketType | null) => {
  const fieldSets = ticketType?.fieldSets;
  let fieldName;
  const customerFields = fieldSets?.find((field) => field.id === 'customerInfo');
  if (!customerFields) {
    throw new Error('Failed to find phonenumber field.');
  } else {
    const phoneField = customerFields?.customerInfo?.find(
      (field) =>
        field.name === 'Puhelinnumero' || field.customType === 'phoneNumber' || field.displayField === 'phoneField'
    );
    fieldName = typeof phoneField?.object === 'string' ? phoneField.object + '.' : '';
    fieldName += phoneField?.value ?? '';
  }

  return {
    [fieldName]: normalizedPhoneNumber,
    taskType: ticketType?.name,
    entityTypes: ticketType?.entityRouting?.map((x) => x.entityType)
  };
};

const keysToEscape = ['accessTokenExpiration', 'sorting', 'direction', 'comments'];
const keysForFiltersThatAreArrays = [
  'channel',
  'tags',
  'categories',
  'tagCategoriesNOT',
  'tagAND',
  'tagNOT',
  'priorities',
  'taskType',
  'taskTypeNOT',
  'delegates'
];

export const getURLFilterParams = (search: string) => {
  const params = queryString.parse(search, {
    parseNumbers: true,
    parseBooleans: true
  });
  const filters: TabFilter = { ...params };

  // TODO: it's not needed if we use arrayFormat prop of queryString.parse/stringify methods
  keysForFiltersThatAreArrays.forEach((key) => {
    if (params[key] !== undefined && params[key] !== null && !Array.isArray(params[key])) {
      filters[key] = [params[key]];
    }
  });

  Object.keys(filters).forEach((filterParam: string) => {
    if (keysToEscape.includes(filterParam)) {
      delete filters[filterParam];
    }
  });

  return filters;
};

export const getUrlSearchParam = (name: string) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  return urlParams.get(name) || undefined;
};

export const getURLParam = <T extends string | number | boolean | (string | number | boolean)[] | null | undefined>(
  search: string,
  name: string
): T => {
  const params = queryString.parse(search, {
    parseNumbers: true,
    parseBooleans: true
  });

  return params[name] as T;
};

export const setHtmlLangAttribute = (lang: string): void => {
  document.documentElement.lang = lang;
};

export const invertHexColor = (hexTripletColor: string): string => {
  let color: number | string = hexTripletColor;
  color = color.substring(1);
  color = parseInt(color, 16);
  color = 0xffffff ^ color;
  color = color.toString(16);
  color = ('000000' + color).slice(-6);
  return '#' + color;
};

export const getLastIncommingComment = (task: Ticket) => {
  const payload: { content: string | undefined; isHtml?: boolean; commentId?: number } = {
    content: undefined
  };
  const comment = task.comments.find(
    (comment) => comment.type !== 'automatic' && comment.direction === 'in' && comment.content !== null
  );

  if (comment) {
    payload.content = comment.content as string;
    payload.commentId = parseInt(comment.id.substring(3), 10);
    if (comment.metaData?.html) {
      payload.isHtml = true;
    }
  }
  return payload;
};
